.img_url {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.img_url img {
    width: 100%;
    height: 100%;
   cursor: pointer;

}
