@import '~antd/dist/antd.css';

body {
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Common CSS style */
.span-button {
  cursor: pointer;
  color: #1890ff;
}
.span-button-disabled {
  cursor: not-allowed;
  color: #d9d9d9
}